import { application } from './application'

import Embedded__LpNavigationController from './embedded/lp_navigation_controller.js'
application.register('lp-navigation', Embedded__LpNavigationController)

import Embedded__LpPaymentController from './embedded/lp_payment_controller.js'
application.register('lp-payment', Embedded__LpPaymentController)

import Embedded__LpSchedulerController from './embedded/lp_scheduler_controller.js'
application.register('lp-scheduler', Embedded__LpSchedulerController)

import Embedded__ProgramSignupController from './embedded/program_signup_controller.js'
application.register('program-signup', Embedded__ProgramSignupController)

import Embedded__PublicController from './embedded/public_controller.js'
application.register('public', Embedded__PublicController)

import SignatureController from '../../components/signature/full_name/component_controller'
application.register('signature', SignatureController)

import CheckboxSignatureController from '../../components/signature/checkbox/component_controller'
application.register('checkbox-signature', CheckboxSignatureController)

import AgreementSignaturesController from './agreement_signatures_controller'
application.register('agreement-signatures', AgreementSignaturesController)

import Components__Address_Field__ComponentController from '../../components/address_field/component_controller.js'
application.register('address-field--component', Components__Address_Field__ComponentController)

import ClickOutsideController from './click_outside_controller'
application.register('click-outside', ClickOutsideController)

import ToggleClassController from './toggle_class_controller'
application.register('toggle-class', ToggleClassController)

import ScrollToController from './scroll_to_controller'
application.register('scroll-to', ScrollToController)
