import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['count', 'navigation', 'submit']
  static outlets = ['signature', 'checkbox-signature']

  _currentIndex = 0
  _outlets = []

  signatureOutletConnected() {
    // We need to push this down the call stack so that the signature outlets
    // have time to connect
    setTimeout(this.update.bind(this))
  }

  checkboxSignatureOutletConnected() {
    // We need to push this down the call stack so that the signature outlets
    // have time to connect
    setTimeout(this.update.bind(this))
  }

  updateCount() {
    const count = this.allOutlets.filter((signatureOutlet) => signatureOutlet.completed).length
    this.countTarget.textContent = count
  }

  next() {
    this.incrementCurrentIndex(this.currentIndex)
    this.update()
    this.scrollToCurrent()
  }

  previous() {
    this.decrementCurrentIndex(this.currentIndex)
    this.update()
    this.scrollToCurrent()
  }

  jumpTo({ target }) {
    this.allOutlets.find((signatureOutlet, index) => {
      if (signatureOutlet.element === target) {
        this.currentIndex = index

        this.update()

        return true
      }

      return false
    })
  }

  jumpToNext({ target }) {
    const outlet = this.allOutlets.find((signatureOutlet) => {
      if (signatureOutlet.element === target) {
        return true
      }

      return false
    })

    const index = this.allOutlets.indexOf(outlet)
    this.incrementCurrentIndex(index)

    this.update()
  }

  scrollToCurrent() {
    const signatureOutlet = this.allOutlets[this.currentIndex]

    signatureOutlet.signatureTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  flashValidity() {
    const signatureOutlet = this.allOutlets[this.currentIndex]

    signatureOutlet.flashValidity()
  }

  ///
  /// private
  ///

  update() {
    this.allOutlets.forEach((signatureOutlet) => {
      signatureOutlet.unhighlight()
    })

    const signatureOutlet = this.allOutlets[this.currentIndex]

    const count = this.allOutlets.filter((outlet) => outlet.completed).length

    if (count === this.allOutlets.length) {
      if (this.hasNavigationTarget) {
        this.navigationTarget.classList.add('hidden')
        this.submitTarget.classList.remove('hidden')
      }
      if (this.hasSubmitTarget) this.submitTarget.disabled = false

      this.allOutlets.forEach((outlet) => {
        outlet.unhighlight()
      })
    } else {
      if (this.hasNavigationTarget) {
        this.navigationTarget.classList.remove('hidden')
        this.submitTarget.classList.add('hidden')
      }
      if (this.hasSubmitTarget) this.submitTarget.disabled = true
    }

    if (signatureOutlet.completed) return

    signatureOutlet.highlight()
  }

  incrementCurrentIndex(start) {
    this.currentIndex = start + 1

    if (this.allOutlets[this.currentIndex].completed) {
      this.currentIndex = this.nextUnsignedIndex(start)
    }
  }

  decrementCurrentIndex(start) {
    this.currentIndex = start - 1

    if (this.allOutlets[this.currentIndex].completed) {
      this.currentIndex = this.previousUnsignedIndex(start)
    }
  }

  nextUnsignedIndex(start) {
    let nextIndex = this.allOutlets.findIndex((signatureOutlet, index) => {
      if (index <= start) return false

      return !signatureOutlet.completed
    })

    if (nextIndex === -1) {
      nextIndex = this.allOutlets.findIndex((signatureOutlet) => !signatureOutlet.completed)
    }

    return nextIndex
  }

  previousUnsignedIndex(start) {
    const reversedStart = this.allOutlets.length - start - 1
    const reversedOutlets = this.allOutlets.slice().reverse()

    let previousIndex = reversedOutlets.findIndex((signatureOutlet, index) => {
      if (index <= reversedStart) return false

      return !signatureOutlet.completed
    })

    if (previousIndex === -1) {
      previousIndex = reversedOutlets.findIndex((signatureOutlet) => !signatureOutlet.completed)
    }

    return this.allOutlets.length - previousIndex - 1
  }

  get allOutlets() {
    if (this._outlets.length) return this._outlets

    const outlets = this.signatureOutlets.concat(this.checkboxSignatureOutlets)

    outlets.sort((a, b) => {
      if (a.element.compareDocumentPosition(b.element) & Node.DOCUMENT_POSITION_FOLLOWING) {
        return -1
      }

      return 1
    })

    this._outlets = outlets

    return this._outlets
  }

  get currentIndex() {
    return this._currentIndex
  }

  set currentIndex(index) {
    if (index < 0) {
      index = this.allOutlets.length - 1
    }

    this._currentIndex = index % this.allOutlets.length
  }
}
