import { Controller } from '@hotwired/stimulus'
import { select } from 'd3'
import { interpolate } from 'flubber'

export default class extends Controller {
  static targets = ['signature', 'input', 'svg']

  UNCHECK_PATH = 'M0 0H48.0652L67 18L48.0652 36H0V0Z'
  CHECK_PATH = `M18 36C22.7739 36 27.3523 34.1036 30.7279 30.7279C34.1036 27.3523 36 22.7739 36 18C36 13.2261
    34.1036 8.64773 30.7279 5.27208C27.3523 1.89642 22.7739 0 18 0C13.2261 0 8.64773 1.89642 5.27208
    5.27208C1.89642 8.64773 0 13.2261 0 18C0 22.7739 1.89642 27.3523 5.27208 30.7279C8.64773 34.1036 13.2261 36 18
    36ZM25.9453 14.6953L16.9453 23.6953C16.2844 24.3562 15.2156 24.3562 14.5617 23.6953L10.0617 19.1953C9.40078
    18.5344 9.40078 17.4656 10.0617 16.8117C10.7227 16.1578 11.7914 16.1508 12.4453 16.8117L15.75 20.1164L23.5547
    12.3047C24.2156 11.6437 25.2844 11.6437 25.9383 12.3047C26.5922 12.9656 26.5992 14.0344 25.9383 14.6883L25.9453
    14.6953Z`
  CHECK_FILL = '#5CB85C'
  ANIMATION_DURATION = 750

  toggleSign() {
    if (this.completed) {
      this.checkSvg()
      this.dispatch('completed', { prefix: 'signature', bubbles: true })
    } else {
      this.uncheckSvg()
      this.dispatch('reset', { prefix: 'signature', bubbles: true })
    }
  }

  highlight() {
    this.signatureTarget.classList.add('shadow-[0px_0px_8px_0px]', 'shadow-kicksite-yellow-600')
    this.signatureTarget.classList.remove('shadow-[0px_0px_12px_0px]', 'shadow-red-500')
  }

  unhighlight() {
    this.signatureTarget.classList.remove('shadow-[0px_0px_8px_0px]', 'shadow-kicksite-yellow-600')
    this.signatureTarget.classList.remove('shadow-[0px_0px_12px_0px]', 'shadow-red-500')
  }

  flashValidity() {
    this.signatureTarget.classList.remove('shadow-[0px_0px_8px_0px]', 'shadow-kicksite-yellow-600')
    this.signatureTarget.classList.add('shadow-[0px_0px_12px_0px]', 'shadow-red-500')

    setTimeout(() => {
      this.signatureTarget.classList.remove('shadow-[0px_0px_12px_0px]', 'shadow-red-500')

      if (this.completed) return

      this.signatureTarget.classList.add('shadow-[0px_0px_8px_0px]', 'shadow-kicksite-yellow-600')
    }, 1500)
  }

  get completed() {
    return this.signatureTarget.checked
  }
  ///
  /// private
  ///

  checkSvg() {
    select(this.svgTarget.querySelector('path'))
      .style('display', 'block')
      .call((sel) => {
        this.animate(sel, this.UNCHECK_PATH, this.CHECK_PATH, this.CHECK_FILL)
      })
  }

  uncheckSvg() {
    select(this.svgTarget.querySelector('path'))
      .style('display', 'block')
      .call((sel) => {
        this.animate(sel, this.CHECK_PATH, this.UNCHECK_PATH)
      })
  }

  animate(sel, start, end, fill) {
    sel
      .datum({ start, end })
      .transition()
      .duration(this.ANIMATION_DURATION)
      .attrTween('d', function (d) {
        return interpolate(d.start, d.end, { maxSegmentLength: 0.1 })
      })
      .style('viewBox', '0 0 48 48')
      .style('fill', fill)
  }
}
